const SCROLL_KEYS = {
  32: 1,
  33: 1,
  34: 1,
  35: 1,
  36: 1,
  37: 1,
  38: 1,
  39: 1,
  40: 1,
};

window.onload = () => {
  initializeNavigation();
};

function initializeNavigation() {
  let overlay = document.querySelector('.overlay');
  overlay.addEventListener('click', (e) => {
    let mobileNav = document.querySelector('.mobile-nav.opened');
    mobileNav.classList.remove('opened');
    overlay.classList.remove('visible');
  });

  setActiveNavItem();
  preventScrolling();
}

function setActiveNavItem() {
  let desktopNav = document.querySelector(".desktop-nav");
  let lang = desktopNav.querySelector(".language-changer").getAttribute("data-active");
  let linkElements = desktopNav.querySelectorAll("a");
  linkElements.forEach((el) => {
    if (el.text.trim() == lang) {
      el.classList.add("language-changer__item--active")
    }
  });
  
  let mobileNav = document.querySelector(".mobile-nav");
  linkElements = mobileNav.querySelectorAll("a");
  linkElements.forEach((el) => {
    if (el.text.trim() == lang) {
      el.classList.add("language-changer__item--active")
    }
  });
}

function preventScrolling() {
  let openButton = document.querySelector('.open-menu-button');
  let closeButton = document.querySelector('.close-menu-button');

  // modern Chrome requires { passive: false } when adding event
  var supportsPassive = false;
  try {
    window.addEventListener(
      'test',
      null,
      Object.defineProperty({}, 'passive', {
        get: function () {
          supportsPassive = true;
        },
      })
    );
  } catch (e) {}

  let wheelOpt: boolean | AddEventListenerOptions = supportsPassive
    ? { passive: false }
    : false;
  let wheelEvent =
    'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

  openButton!.addEventListener('click', (e) => {
    let nav = document.querySelector('header nav');
    nav!.classList.add('opened');
    let overlay = document.querySelector('.overlay');
    overlay!.classList.add('visible');

    // prevent scroling when navigation is open
    window.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
    window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
    window.addEventListener('touchmove', preventDefault, wheelOpt); // mobile
    window.addEventListener('keydown', preventDefaultForScrollKeys, false);
  });

  closeButton!.addEventListener('click', (e) => {
    let nav = document.querySelector('header nav');
    nav!.classList.remove('opened');
    let overlay = document.querySelector('.overlay');
    overlay!.classList.remove('visible');

    // Re-enable scrolling
    window.removeEventListener('DOMMouseScroll', preventDefault, false);
    window.removeEventListener(wheelEvent, preventDefault, wheelOpt);
    window.removeEventListener('touchmove', preventDefault, wheelOpt);
    window.removeEventListener('keydown', preventDefaultForScrollKeys, false);
  });

  var prevScrollpos = window.scrollY;
  window.onscroll = function () {
    var currentScrollPos = window.scrollY;
    if (prevScrollpos > currentScrollPos) {
      document.querySelector('header')!.style.top = '0';
    } else {
      document.querySelector('header')!.style.top = '-56px';
    } 
    prevScrollpos = currentScrollPos;
  };
}

function preventDefault(e: TouchEvent | MouseEvent | KeyboardEvent) {
  e.preventDefault();
}

function preventDefaultForScrollKeys(e: KeyboardEvent) {
  if (SCROLL_KEYS[e.keyCode]) {
    preventDefault(e);
    return false;
  }
}
